.main {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.signInOptions button {
}

.signInOptions {
    width: 300px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.signInOptions > * {
    flex-grow: 1
}

.signInOptions button {
    width: 100%;
    height: 56px;
}

.passwordStrength {
    width: 100%;
}

.errorMessage {
    color: var(--error-color);
    font-size: smaller;
    margin-top: 10px;
}

.warning label {
    color:var(--warning-color)  !important;
}
.warning fieldset {
    border-color:var(--warning-color)  !important;
}
.warning p {
    color:var(--warning-color)  !important;
}

.email {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    /* center horizontally */
    align-items: center;
}

.email button {
    width: 300px;
    height: 56px;
}

.horizontalLine {
    width: 100%;
    height: 1px;
    background-color: var(--neutral-color-0);
    margin-top: 20px;
    margin-bottom: 20px;
}
