/* Container styles */
.container {
    position: relative;
}

.children {
    position: relative;
}

.container>div.hover.hidden {
    display: none;
}

/* Initially hide the text */
.container>div.hover {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    color: white; /* Customize text color */
    background-color: rgba(0, 0, 0, 0.7); /* Customize background color */
    /*transform: translate(-50%, -50%); !* Center the text *!*/
    padding: 10px; /* Add padding for better visibility */
    border-radius: 5px; /* Add rounded corners */
    z-index: 1; /* Ensure text appears above the image */

    transform-origin: top right; /* Change the transform origin to top left */
    transform: scaleX(1); /* Scale to full width when hovered */

}
