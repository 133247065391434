.container {
    padding: 24px;
    max-width: 800px;
}

.actionArea {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.buttonText {
    margin-left: 8px;
}

.progressContainer {
    padding: 24px;
    display: flex;
    gap: 24px;
    background-color: #f5f5f5;
    min-height: 200px;
    overflow: hidden;
}

.progressInfo {
    flex-grow: 1;
    width: 100%;
}

.progressHeader {
    margin-bottom: 24px;
}

.recentExportsContainer {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    height: 200px;
    margin: 0 0;
    overflow: hidden;
    position: relative;
}

@keyframes slideAnimation {
    from {
        right: -144px;
    }
    to {
        right: 1584px;
    }
}


.exportEntry {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 8px;
    width: 128px;
    height: 128px;
    right: -144px;
    animation: slideAnimation 10s linear
}

.entryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-size: 0.875rem;
}

.entryEmoji {
    font-size: 1.3em;
}
.entryDate {
    font-size: 1.1em;
}

.entryImage {
    width: 128px;
    height: 128px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    will-change: transform;
}

.entryImage:hover {
    transform: scale(1.05);
}

/* Customize scrollbar for better visual appearance */
.recentExportsContainer::-webkit-scrollbar {
    height: 6px;
}

.recentExportsContainer::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 3px;
}

.recentExportsContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.recentExportsContainer::-webkit-scrollbar-thumb:hover {
    background: #999;
}
