.mainContainer {
    display: flex;
    height: calc(100vh - var(--banner-height) - var(--footer-height) - 30px);
    margin-left: 10px;
    margin-right: 10px;
    background-color: #425457;
}

.mainContainer .loading {
    width: calc(100vw - 20px);
    /* center text horizontally and vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.mainContainer .loading div {
    padding: 10px;
}

.debugInfo {
    position: absolute;
    right: 0;
    z-index: 1;
}

.mainCalScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - var(--banner-height) - var(--footer-height) - 30px);
}

.mainCalScroll::-webkit-scrollbar {
    display: none
}

.dayItem {
    position: relative;
    width: calc(100vw - 20px);
    margin-top: 1px;
    margin-bottom: 1px;
    height: 256px;
    background-color: var(--neutral-color-0);
}

.scrollable {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}
/* Hide scrollbar for webkit browsers (Chrome, Safari) */
.scrollable::-webkit-scrollbar {
    display: none;
}

/* first .dayItem div */
.dayItem>div:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
}


div.summarySvg {
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    height: 0;
    padding-bottom: 100%; /* Adjust this value based on the aspect ratio */
    position: relative;
    border: 1px solid var(--neutral-color-1);
    border-radius: 5px;
}

div.summarySvg svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.imageHolder.addEntry
{
    background-color: var(--neutral-color-0-80-alpha);
    position: absolute;
    width: 100px;
    right: 10px;
    height: 75px;
    /* center vertically */
    transform: translateY(-50%);
    top: 50%;
    border-radius: 5px;
}

.imageHolder.addEntry.bounce {
    animation-name: bounce;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0%, 10%, 20% {
        transform: translateY(-50%);
    }
    5%, 15% {
        transform: translateY(-55%);
    }
}

/*top: calc(50% - 37px);*/
.imageHolder.showWelcome {
    /*font-size: smaller;*/
}


button.showWelcome {
}

.imageHolder.showWelcome span.showWelcome {
    background-color: #163f6e;
    color: white;
}

.imageHolder.empty {
    width: calc(100vw - 130px);
    /* center text horizontally and vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 200px;
}

.imageHolder button {
    /* center horizontally and vertically */
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    color: var(--neutral-color-0);
    transform: translate(-50%, -50%);
}

.partialJE {
    /* center contents vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    width: 198px;
    height: 198px;
    margin-top: 2px;
    margin-left: 2px;
}

.imageHolder .summaryEmoji {
    position: absolute;
    padding-top: 3px;
    padding-right: 3px;
    font-size: x-large;
    right: 0;
    top: 0;
}

.offlineUneditable {
    position: absolute;
    width: 80px;
    height: 80px;
    top: calc(200px / 2 - 80px / 2);
    left: calc(200px / 2 - 80px / 2);
    /* center horizontally and vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-color-0-80-alpha);
    border-radius: 10px;
}

.imageHolder {
    position: relative;
}

.dayItem img {
    width: 200px;
    height: 200px;
    padding: 2px;
}

.dayItem>div {
    display: flex;
    flex-direction: row;
}