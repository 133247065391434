.main button {
    border: none;
    background-color: transparent;
}

.deactivateButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.deactivateButton button {
    margin-left: 16px;
    background-color: #163f6e;
    height: 30px
}