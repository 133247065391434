.main {
    padding-left: 20px;
    padding-top: 20px;
}

.main div {
    padding-top: 10px;
    padding-bottom: 10px;
}


.main div.MuiTextField-root {
    margin-top: 10px;
}

div.main div.oneThing {
    margin-top: 20px;
    font-size: revert;
}

.confirmActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.deleteButton {
    /*border: 1px solid red;*/
    /*background-color: red;*/
    /*color: white;*/
}
