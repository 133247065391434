.scrollContainer {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
  width: 100%;
}

/* Base styles for the grid container */
.matrixContainer {
  display: grid;
  justify-content: center;
  align-content: start;
  margin: 0 auto;
  width: 100%;
  gap: 8px;
  padding: 16px;
  min-height: min-content;
  box-sizing: border-box;
}


.prompt {
    margin-bottom: 16px;
    margin-top: 8px;
    font-size: .9rem;
}

.dialogContent label {
  /*font-size: smaller;*/
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hourGlass {
  animation: spin 2s linear infinite;
}

.previewText {
  font-size: x-small;
}

.previewError {
  color: red;
  font-size: x-small;
  width: 95%;
  text-align: center;
  margin: auto;
}

.personaCell {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  aspect-ratio: 1 / 1;
  width: 100%;
  height: auto;
}

.personaCell:hover {
  transform: scale(1.05);
  border-color: #1976d2;
}

.personaCell.selected {
  background-color: #e3f2fd;
  border-color: #1976d2;
}

.green {
  color: green;
}

@keyframes glowPulse {
  0% {
    transform: scale(1);
    /*box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);*/
  }
  50% {
    transform: scale(1.2);
    /*box-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.7);*/
  }
  100% {
    transform: scale(1);
    /*box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);*/
  }
}

.glow {
  animation: glowPulse 2s infinite;
}

.personaCell img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Grid sizes */
.grid3 {
  grid-template-columns: repeat(3, 1fr);
  /*grid-template-rows: repeat(3, 1fr);*/
}

.grid4 {
  grid-template-columns: repeat(4, 1fr);
  /*grid-template-rows: repeat(4, 1fr);*/
}

.grid5 {
  grid-template-columns: repeat(5, 1fr);
  /*grid-template-rows: repeat(5, 1fr);*/
}

.customCell {
  background-color: #f5f5f5;
  border-style: dashed;
}

.customCellContent {
  text-align: center;
  color: #666;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
}

.customCell:hover .customCellContent {
  color: #1976d2;
}

/* Center the custom cell for each grid size */
.grid3 .customCell {
  grid-column: 2;
  grid-row: 2;
}

.grid4 .customCell {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
}

.grid5 .customCell {
  grid-column: 3;
  grid-row: 3;
}

.customCellContent:hover {
  color: #1976d2;
}

.questionMark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.questionMark:hover {
  background-color: #e0e0e0;
}
