.selectedEmoji {
    background-color: var(--neutral-color-1)!important;
    /*border: 4px solid var(--neutral-color-1)!important;*/
    width: 39px!important;
    height: 39px!important;
}

.entrySummaryButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.entrySummaryButtons button {
    width: 50px;
    height: 50px;
}
