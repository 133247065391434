.panel {
    width: 100%; /* Adjust to match the width of your writingPanel */
    /*height: 600px; !* Adjust to match the height of your writingPanel *!*/
    background-color: white;
    height: 50vh;
    min-height: 50vh;
    padding: 10px;
    border: 1px solid #000;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    /* rest of your styles */
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95vw;
    margin-left: 10px;
    align-items: center;
}

.headingText {
    font-size: larger;
}

.reflectImage {
    width: 100%;
}

button.refreshReflectImage {
    position: absolute;
}

.reflectionPanel {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.reflectionPanel div {
    flex: 1;
    padding: 10px;
    border: 1px solid #000;
    position: relative;
}

.reflectionPanel div:nth-child(2) button {
    right: 10px;
}

.addNewEntry {
    border: black solid 2px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}
