.dialogPreamble {
    margin-left: 20px;
    margin-right: 20px;
    /* make it look like a material card */
}
.dialogMain {
    height: calc(100vh - var(--footer-height) - var(--banner-height));
}
.reflectContent {
    padding: 20px;
}