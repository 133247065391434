.main {
    padding-left: 20px;
}

.convert div p {
    font-size: revert;
}

.convert {
    border: 1px var(--warning-color) solid;
    padding-right: 32px !important;
}

.convert :global(.MuiAlert-icon) {
    padding-top: 32px;
}

.convert button {
    flex-grow: 1;
}

.error {
    color: var(--error-color);
}

.conversionDialog {
}


.actions {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}

.resume {
}

.actions.resume {
    justify-content: space-around;
}


/* first cell of a tr */
.main tr td:first-child {
    /*padding-top: 2px;*/
}

.radioGroup {
    /*border: 1px solid #ccc;*/
    /*margin: 5px;*/
    /*padding: 10px;*/
    /*border-radius: 10px;*/
}

.radioGroup p {
    font-size: larger;
}

.main td {
    vertical-align: top;
}

.main td h3 {
    padding-top: -10px;

}

.subField {
    margin-left: 40px;
}