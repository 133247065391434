body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* hide consent management provider settings recall box, since we have a direct link in settings. */
#cmpboxrecall {
  display: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --sat: env(safe-area-inset-top);

  /* Primary Colors */
  --primary-color-1: #000002; /* Main color, e.g., the dominant color in the image */
  --primary-color-2: #1a343c; /* Secondary color, e.g., a prominent secondary color in the image */

  /* Accent Colors */
  --accent-color-1: #425457; /* An accent color, e.g., a color from a significant but smaller element in the image */
  --accent-color-2: #0b1922; /* Another accent color */

  /* Neutral Colors */
  --neutral-color-0: #f9fbfc; /* Light color for backgrounds */
  --neutral-color-0-80-alpha: #f9fbfccc; /* Light color for backgrounds */
  --neutral-color-1: #c4d5dd; /* Light color for backgrounds */
  --neutral-color-2: #030814; /* Dark color for text */

  /* Additional Shades (if needed) */
  --shade-color-1: #ffffff; /* Lighter or darker shades of primary or accent colors */
  --shade-color-2: #ededdf;

  --banner-height: 60px;
  --footer-height: 30px;

  --warning-color: #FFC107;
  --error-color: #d32f2f;
}

.privacyPolicy {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: calc(var(--footer-height) + 20px);
  height: 100%;
}

button.MuiButton-containedPrimary {
    background-color: #163f6e;
    color: var(--neutral-color-0);

}

/* Apply the animation to an element */
.flip-in {
  animation-name: flip-in; /* Reference the keyframes by name */
  animation-duration: .5s; /* Set the duration of one cycle */
  animation-timing-function: ease; /* Define the timing function */
  animation-fill-mode: both; /* Maintain the end state after the animation */
}

button.react-calendar__tile abbr {
  position: absolute;
  top: .3vw;
  left: .33vw;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: larger;
  padding-top: 10px;
  border-bottom-right-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--shade-color-2);
  border-bottom: 1px var(--primary-color-1) solid;
  border-right: 1px var(--primary-color-1) solid;
}

.react-calendar__month-view__weekdays {
  font-size: x-large;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}



button.react-calendar__tile {
  position: relative;
  display: flex;
  border: none; /* 1px solid var(--accent-color-1); */
  margin-top: -1px;
  width: 12vw;
  height: 12vw;
  padding-block: 0;
  padding-inline: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color-1)
}

.react-calendar__esday {
  width: 95%;
  height: 95%;
  background-size: cover;
  background-position: center;
  background-blend-mode: lighten;
  /*background-color: white;*/
  background-color: rgba(255, 255, 255, .2);
}

/*span.react-calendar__navigation__label__labelText {*/
/*    font-size: xxx-large;*/
/*}*/

.react-calendar__esday.react-calendar__esday--empty {
  width: 95%;
  height: 95%;
  background-size: cover;
  background-position: center;
  background-blend-mode: lighten;
  /*background-color: white;*/
  background-color: white;
}

.react-calendar__esday_emoji {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: x-large;
  width: 100%;
}

.react-calendar__navigation button {
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 0 !important;
  font-size: x-large;
  background-color: var(--neutral-color-1);
  border-radius: 4px;
  border: 1px solid var(--neutral-color-1);
}

/* 3rd button is month, make it unclickable */
.react-calendar__navigation button:nth-child(3) {
  pointer-events: none;
  flex-grow: 0;
  padding-left: 50px;
  padding-right: 50px;
  background-color: unset;
  font-size: xxx-large;
  border: none
}

.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.react-calendar__month-view__weekdays__weekday {
  align-items: center;
  text-align: center;
}

.hidden {
  display: none;
}

.spacer {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

a {
  color: var(--primary-color-2);
}

@keyframes flip-in {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}



/* *********
BRANDING FOR GOOGLE BUTTON (COPY AND PASTE FROM https://developers.google.com/identity/branding-guidelines
***** */
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
/* *********
END BRANDING FOR GOOGLE BUTTON (COPY AND PASTE FROM https://developers.google.com/identity/branding-guidelines
***** */
