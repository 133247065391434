.images {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

/*.images > div {*/
/*    flex-basis: calc(50% - 20px); !* 25% width for each div, subtract margin *!*/
/*    margin: 10px; !* Adjust margin as needed for spacing *!*/
/*    box-sizing: border-box;*/
/*}*/

.images img , .noImageDiv {
    max-width: calc(min(512px,50vw - 20px));
    min-width: calc(min(512px,50vw - 20px));
    width: auto;
    height: auto;
    object-fit: contain; /* Maintain aspect ratio and fit within the container */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.noImageDiv {
    width: 512px;
    height: 512px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.noImageDiv svg {
    width: 64px;
    height: 64px;
}

.noImageDiv:global(.waiting) svg {
    color: var(--neutral-color-1)
}
.noImageDiv:global(.error) svg {
    color: var(--error-color);
}
/* spin hour glass */
.noImageDiv:global(.processing) svg {
    animation: spin 2s linear infinite;
}
/* create spin animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.imageWrapper {
    max-width: calc(min(512px,50vw - 20px));
    min-width: calc(min(512px,50vw - 20px));
    width: calc(min(512px,50vw - 20px));
    height: auto;
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.prompt {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin: 10px;
}

.prompt button {
    margin-top: 10px;
}

.labels {
    display: flex;
    justify-content: space-between;
    margin: 10px
}

.labels div span:nth-child(1) {
    font-weight: bold;
    margin-right: 5px;
}

