.drawingArea {
    margin: 8px;
    display: flex;
}

.outlet {
    display: flex;
    flex-direction: column;
    margin: 8px;
}

.path {
    font-size: larger;
    font-weight: bold;
}