.freeNote {
    font-size: small;
}

.main {
    margin-top: 10px;
}

.greenCheck {
    color: #4CAF50;
    font-weight: bold;
}

td.centerText {
    text-align: center
}

.selectAPlan {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.5em;
}

td.plan {
    vertical-align: top;
    font-weight: bold;
    text-align: center;
}

.animated {
    /* spin in circle */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/** button has div, with two divs inside Make the second div in the button's first div child an overlay on top of second div **/
td.plan button>div {
    position: relative;
    width: 100%;
    height: 100%;
}
td.plan button>div>div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}