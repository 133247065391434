.grid {
    margin: 0;
    padding: 16px;
    justify-content: flex-start;
}

.addCard {
    width: 256px;
    height: 256px;
    border: 2px dashed rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.addCard:hover {
    border-color: rgba(0, 0, 0, 0.24);
    background-color: rgba(0, 0, 0, 0.04);
}
