.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 256px;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
}

.dragHandle {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: move;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: background-color 0.3s;
}

.dragHandle:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.dragging {
    opacity: 0.6;
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    pointer-events: none;
}

.mediaContainer {
    position: relative;
    width: 256px;
    height: 256px;
    overflow: hidden;
}

.errorMessage {
    color: red;
    width: 80%;
    text-align: center;
    align-content: center;
    margin: auto;
    height: 100%;
}

.media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

.hourGlass {
    font-size: 48px;
    animation: spin 2s linear infinite;
}

.name {
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 4px !important;
}

.description {
    color: rgba(0, 0, 0, 0.6);
}

.actions {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
}

.actionButton {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: white !important;
    padding: 4px !important;
}

.actionButton:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
