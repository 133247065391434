.main {
    position: relative;
}

.main>button {
    position: absolute;
    z-index: 2;
    top: 2px;
    right: 40px;
    background: var(--shade-color-2);
}