.canvas {
    background: #F5F5DC;
    padding: 0;
    margin: 0;
}

.sidebar {
    position: absolute;
}

.sidebar button {
    width: 40px;
    height: 40px;
}

.selectedIcon {
    border: 2px solid #000000!important;
}

.sidebarActive {
    display: none
}

.sidebar button {
    margin: 8px;
    background: rgba(255, 255, 255, 0.8);
}