/* src/app/styles.module.css */

.container {
    color: var(--shade-color-1);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    height: var(--banner-height);
    display: flex;
    align-items: flex-end;
    padding-top: max(5px, calc(env(safe-area-inset-top) - 20px));
    padding-bottom: 5px;
    background-color: var(--primary-color-2);
    z-index: 3000;
}

.header a {
    color: var(--shade-color-1);
    text-decoration: none;
}

.header h1 {
    margin: 10px;
}

.header nav ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 5px;
}

.popupMenu {
    position: absolute;
    background-color: var(--primary-color-2);
    top: 50px;
    right: 3px;
    padding: 0;
    border-radius: 5px;
    border: 1px solid var(--shade-color-1);
}

.popupMenu ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 10px !important;
}

.hamburgerMenu {
    padding-right: 20px;
    align-self: flex-end;
    margin-bottom: 10px;
}

.header nav ul li {
    padding-left : 10px;
    padding-right : 10px;
}

.selMenu {
    text-decoration: underline;
}

.main {
    display: flex;
    color: var(--primary-color-2);
    padding-top: calc(var(--banner-height) + 20px + var(--safe-area-minus-20));
    padding-bottom: calc(var(--footer-height) + 10px);
    width: 100%;
    height: calc(100vh - var(--banner-height) - var(--footer-height) - 30px);
    /*max-height: calc(100vh - var(--banner-height) - var(--footer-height) - 30px);*/
    flex: 1;
    background-color: var(--neutral-color-0);
}

/* src/app/styles.module.css */

.calendarIframe {
    flex: 3;
    width: 100%;
    height: 100%;
    border: none;
    border-right: 1px solid #e0e0e0;
}


.calendar {
    flex: 3;
    border-right: 1px solid #e0e0e0;
    padding-right: 20px;
}

.sidebar {
    flex: 1;
    padding-left: 20px;
}

.footer {
    position: fixed;
    bottom: 0;
    height: var(--footer-height);
    left: 0;
    display: flex;
    width: calc(100% - 40px);
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    color: var(--primary-color-1);
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
}

.footer ul {
    display: flex;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
}
