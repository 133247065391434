.panel {
    width: 100%; /* Adjust to match the width of your writingPanel */
    /*height: 600px; !* Adjust to match the height of your writingPanel *!*/
    background-color: var(--neutral-color-0);
    /* Use dvh (dynamic viewport height) with vh as fallback for better handling of mobile browser toolbars */
    height: calc(100vh - var(--banner-height) - var(--footer-height) - 170px);
    height: calc(100dvh - var(--banner-height) - var(--footer-height) - 170px);
    min-height: 50vh;
    padding: 10px;
    padding-bottom: 20px;
    border: 0;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    /* rest of your styles */
}

.panel :global(.MuiFormHelperText-root) {
    margin-top: -20px;
}

.reflectImage {
    width: 100%;
}

.writeActionPanel {
    width: 95%;
}
.fabButton {
    position: absolute!important;;
    top: 18px!important;;
    right: 30px!important;;
    background-color: #163f6e!important;
    color: var(--shade-color-2)!important;
    width: 40px!important;;
    height: 40px!important;;
}

.loadingOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1;
    /* center text horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingOverlay span {
    color: white;
    font-size: 30px;

}



button.refreshReflectImage {
    position: absolute;
}

.addNewEntry {
    border: black solid 2px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}
